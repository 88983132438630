import { api } from '@/services/api'
import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables'

export default function useUser() {

  const textSearch = ref('')
  const shopList = ref([])
  const loading = ref(false)
  const isAddNewUser = ref(false)
  const userDetail = ref({})
  const isShowDetail = ref(false)
  const isUpdateStatus = ref(false)
  const isEdit = ref(false)
  const headers = ref([
    {
      text: '#',
      value: 'user_id',
      width: '50',
      align: 'center',
    },
    {
      text: 'ชื่อ - นามสกุล',
      value: 'user_fullname',
      width: '200',
    },
    {
      text: 'Username',
      value: 'username',
      width: '200',
    },
    {
      text: 'ร้านค้า',
      value: 'shop_name',
      width: '200',
    },
    {
      text: 'สถานะ',
      value: 'user_active',
      width: '100',
    },
    {
      text: 'ตัวเลือก',
      value: 'actions',
      align: 'center',
    },
  ])
  const itemsPerPage = ref(10)
  const status = ref('1')
  const footer = ref(footerDataTables)

  const changeStatus = (id) => {
    status.value = id
    getUserList(id)
  }

  const getUserList = () => {
    loading.value = true
    api.get({
      path: '/admin/users',
      param: `?search=${textSearch.value}&active=${status.value}`,

    }).then(res => {
      const { data } = res
      shopList.value = data
      loading.value = false
    })
  }

  onMounted(() => {
    getUserList()
  })

  return {
    isAddNewUser,
    isUpdateStatus,
    headers,
    shopList,
    changeStatus,
    isEdit,
    footer,
    userDetail,
    itemsPerPage,
    getUserList,
    isShowDetail,
    loading,
    textSearch,
  }
}
