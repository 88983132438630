<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <span>ข้อมูลผู้ใช้งานประจำร้าน</span>
        <v-spacer></v-spacer>
        <v-btn @click='isAddNewUser = true' color='primary'>เพิ่มผู้ใช้งาน</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        label='ค้นหาผู้ใช้งานประจำร้าน'
                        @input='getUserList()'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus' />
        </v-col>
      </v-row>
      <v-data-table
        :headers='headers'
        :items='shopList'
        :items-per-page='itemsPerPage'
        :loading='loading'
        no-data-text='ไม่มีข้อมูล !'
        :footer-props='footer'
        no-results-text='ไม่มีข้อมูล !'
        loading-text='กำลังโหลดข้อมูล...'
        class='elevation-1'
      >
        <template #[`item.user_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.user_active`]='{ item }'>
          <StatusBlock :status='String(item.user_active)' />
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                icon
                v-bind='attrs'
                @click='userDetail = item;isEdit = true'
                v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                icon
                v-bind='attrs'
                @click='userDetail = item;isUpdateStatus = true'
                v-on='on'
              >
                <v-icon>{{ item.user_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.user_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewUser v-model='isAddNewUser' @onAdd='getUserList' />
    <EditUser v-model='isEdit' :data-edit-user='userDetail' @onUpdate='getUserList' />
    <SuspendUser v-model='isUpdateStatus' :user-data='userDetail' @onUpdate='getUserList' />

  </div>
</template>

<script>

import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiPlus,
  mdiStoreOutline,
  mdiTextBoxCheck,
  mdiCheck,
} from '@mdi/js'
import useUser from '@/views/administrator/users/useUser'
import AddNewUser from '@/views/administrator/users/AddNewUser'
import EditUser from '@/views/administrator/users/EditUser'
import SuspendUser from '@/views/administrator/users/SuspendUser'
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'

export default {
  props: {},
  components: {
    StatusBlock,
    FilterStatus,
    AddNewUser,
    EditUser,
    SuspendUser,
  },
  setup() {
    return {
      ...useUser(),
      mdiPencilOutline, mdiDeleteOutline, mdiCheck,
    }
  },

}
</script>

<style scoped>

</style>
