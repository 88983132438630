<template>
  <div>
    <v-dialog v-model='isAddNewUser' persistent max-width='500'>
      <v-card>
        <v-card-title>เพิ่มผู้ใช้งาน</v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddUser' @submit.prevent='createUser'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12'>
              <v-select :items='shopList' v-model='userData.shop_id' item-text='shop_name'
                        hide-details='auto'
                        no-data-text='ไม่มีข้อมูล !'
                        item-value='shop_id' dense filled label='เลือกร้านค้า'
                        placeholder='เลือกร้านค้า'></v-select>
            </v-col>
            <v-col cols='12'>
              <v-text-field v-model='userData.user_fname' filled dense
                            label='ชื่อ'
                            :rules='[required]'
                            placeholder='ชื่อ'
                            hide-details='auto'>
              </v-text-field>
            </v-col>
            <v-col cols='12'>
              <v-text-field v-model='userData.user_lname' filled dense
                            label='นามสกุล'
                            :rules='[required]'
                            placeholder='นามสกุล'
                            hide-details='auto'>
              </v-text-field>
            </v-col>
            <v-col cols='12'>
              <v-text-field v-model='userData.username' filled dense
                            label='Username'
                            :rules='[required]'
                            placeholder='Username'
                            hide-details='auto'>
              </v-text-field>
            </v-col>
            <v-col cols='12'>
              <v-text-field v-model='userData.password' filled dense
                            label='Password'
                            type='password'
                            :rules='[required]'
                            placeholder='Password'
                            :type="isPasswordVisible ? 'text' : 'password'"
                            :append-icon='isPasswordVisible ? mdiEyeOffOutline:mdiEyeOutline'
                            @click:append='isPasswordVisible = !isPasswordVisible'
                            hide-details='auto'>
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>บันทึก
            </v-btn>
            <v-btn color='secondary' outlined @click='$emit(`update:isAddNewUser`)'>ยกเลิก</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { api } from '@/services/api'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewUser',
    event: 'update:isAddNewUser',
  },
  props: {
    isAddNewUser: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const userData = ref({
      user_fname: '',
      user_lname: '',
      role_id: 3,
      shop_id: '',
      username: '',
      password: '',
    })
    const shopList = ref([])
    const formAddUser = ref(null)
    const loading = ref(false)
    const isPasswordVisible = ref(false)


    const getShopList = () => {
      api.get({
        path: '/admin/shops',
      }).then(({ data }) => {
        shopList.value = data
        userData.value.shop_id = data[0].shop_id
      }).catch(error => {
        console.log('error : ', error)
      })

    }

    const createUser = () => {
      const isFormValid = formAddUser.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/admin/user',
        body: userData.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewUser', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        loadign.value = false
        console.log('error : ', err)
      })
    }

    watch(() => props.isAddNewUser, value => {
      if (value) {
        getShopList()
        userData.value = {
          user_fname: '',
          user_lname: '',
          role_id: 3,
          shop_id: '',
          username: '',
          password: '',
        }
      }
    })
    return {
      isPasswordVisible,
      loading,
      shopList,
      createUser,
      formAddUser,
      required,
      userData,
      mdiEyeOffOutline, mdiEyeOutline,
    }
  },

}
</script>

<style scoped>

</style>
